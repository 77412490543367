<template>
<div style="margin-left: 0.5%">
  <h2><font-awesome-icon icon="plus" />Ajouter</h2>
  <br>
  <div class="tableMap">
    <form action="https://marc-antoine-lune.github.io/cesit_app/?#/addPage" >
      <div class="table">
        <div v-for="key in keys" :key="key">
          <label :for=key>{{key}}</label><br>
          <input type="text" class="input" v-model="form[key]">
        </div>
        <div class="lat">
          <label for="latitude">LATITUDE</label><br>
          <input type="text" v-model="this.lat">
        </div>
        <div>
          <label for="longitude">LONGITUDE</label><br>
          <input type="text" v-model="this.long">
        </div>
      </div>
      <br>
      <button @click="add">ENREGISTRER</button>
      <span v-if="this.sendSucess" v-bind:class="{success: sendSucess == 'Données enregistrées', failure: sendSucess == 'Erreur enregistrement' }"><br>{{this.sendSucess}}</span>
    </form>
    <div class="map">
      <AddMap :key="this.reload"/>
    </div>
  </div>
  </div>
</template>

<script>
import AddMap from '../components/AddMap'
import {bus} from '../main'
export default {
  components: {
    AddMap
  },
  data() {
   return {
     keys: [],
     form: [],
     lat:"",
     long:"",
     sendSucess: "",
     reload: false,
     
   }
  },
  methods:{
    add(){
      var postBody={};        
      Object.entries(this.keys).forEach((key)=>{
          if(this.form[key[1]]){
            if(key[1] == "ROWID" || key[1] == "ID"){
              postBody[key[1]] = Number(this.form[key[1]])
            }else{
              postBody[key[1]] = this.form[key[1]]
          }
          }else{
            if(key[1] == "ID") postBody[key[1]] = Number(Date.now());
            else postBody[key[1]] = null
          }
         
        })

        var url = process.env.VUE_APP_API_HOST + "postDatas/"
        const postMethod = {
          method: 'POST', 
          headers: {
            'Content-type': 'application/json; charset=UTF-8' 
          },
          body:JSON.stringify({
            "type" : "Feature",
            "properties" : postBody,
            "geometry": {
                "type": "Point",
                "coordinates": [
                  this.long,
                  this.lat
                ]
            }
           } )
          }

      fetch(url, postMethod)
      .then(response => response.json())
      .then(data =>{ 
        console.log(data);
        this.sendSucess = "Données enregistrées";
        this.form = [];
        this.lat ="";
        this.long = "";
        this.reload = !this.reload;
        setTimeout(() => {
          this.sendSucess=""}, 2000);
      }) 
      .catch(err =>{
        console.log(err);
        this.sendSucess = "Erreur Enregistrement";
        setTimeout(() => {
          console.log("timeout")
          this.sendSucess=""}, 2000);
      } ) 
    } 
  },
  async created(){    
    const response = await fetch(process.env.VUE_APP_API_HOST + "getkeys");
    let data = await response.json();
    data.forEach(element => {
        this.keys.push(element)
    })

    bus.$on("datas", (data)=>{
      this.lat = data.position.lat;
      this.long = data.position.lng;
    })
  }
}
</script>

<style scoped>

.tableMap{
  display: flex;
  flex-direction: row;
}

input {
  border: solid 1px black;
  max-width: 100px;
  border-radius: 2px;
  height: 30px;
  margin-right: 5px;
}

.table{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  max-width: 90%;
}

 button{ 
  text-decoration: none; 
  border: none; 
  padding: 12px 40px; 
  font-size: 16px; 
  background-color:  #f5f5f570; 
  color: black; 
  border-radius: 2px; 
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
  cursor: pointer; 
  outline: none; 
  transition: 0.2s all; 
} 
          
button:active { 
  transform: scale(0.98); 
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
} 

button:hover{
    filter: brightness(0.9);

}

.success{
  position: fixed;
  bottom: 20px;
  height: 50px;
  width: auto;
  background-color:green;
  color: white;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
  border-radius: 2px; 
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  text-align: center;

}

.failure {
  position: fixed;
  bottom: 20px;
  height: 50px;
  width: auto;
  background-color:red;
  color: white;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
  border-radius: 2px; 
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  text-align: center;

}


  
</style>
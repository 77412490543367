<template>
  <div id="container">
   <div id="mapContainer"></div>
   <button @click="addMarker">AJOUTER UN MARKER</button>
 </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {Icon} from "leaflet";
import {bus} from "../main";
import { store, mutations } from "./Store.js";
//import {LExtended as L} from 'geoportal-extensions-leaflet';


delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
     
var mapDiv;
var markers = []

export default {
     name: "Map",
 data() {
   return{
     locations: [],
     layer: "",
     mapCenterPosition: {},
   }
   },
 methods: {
   setupLeafletMap() {
    var cadastre = L.tileLayer(
                      "https://wxs.ign.fr/choisirgeoportail/geoportail/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=PCI vecteur&TILEMATRIXSET=PM&FORMAT=image/png&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
                  ,	{
                      minZoom : 0,
                      maxZoom : 18,
                      attribution : "IGN-F/Geoportail",
                      tileSize : 256 // les tuiles du Géooportail font 256x256px
                    })
     var ign = L.tileLayer(
                      "https://wxs.ign.fr/choisirgeoportail/geoportail/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/png&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"                  
                      ,	{
                      minZoom : 0,
                      maxZoom : 18,
                      attribution : "IGN-F/Geoportail",
                      tileSize : 256 // les tuiles du Géooportail font 256x256px
                    })
     var sat = L.tileLayer(
                      "https://wxs.ign.fr/choisirgeoportail/geoportail/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
                      ,	{
                      minZoom : 0,
                      maxZoom : 18,
                      attribution : "IGN-F/Geoportail",
                      tileSize : 256 // les tuiles du Géooportail font 256x256px
                    })

    var baseMaps = {
      "ign": ign,
      "sat": sat

    };   
    var overlayMap = {
      "cadastre": cadastre,
    };    
 
    mapDiv = L.map("mapContainer").setView([this.position.lat == undefined ? 42 : this.position.lat, this.position.lng == undefined ? 9 : this.position.lng], this.zoom == undefined ? 9 : this.zoom)          
            .on("moveend", () => {
                this.setPosition(mapDiv.getCenter())
            })
            .on("zoomend", () =>{
                this.setZoom(mapDiv.getZoom())

            })  
    ign.addTo(mapDiv)

    L.control.layers(baseMaps, overlayMap).addTo(mapDiv) ;
     this.locations.forEach(element =>{
          var mark = L.marker([element.coordinates[1], element.coordinates[0]], { opacity: 0.5})
          .on("dragend", (e) => {
            var marker = e.target;
            var position = marker.getLatLng();
            this.shareDatas(element.id, position)
            //map.panTo(new L.LatLng(position.lat, position.lng));
          })
          .addTo(mapDiv)
          .bindTooltip(
            element.name, {permanent: true, className: "myLabels", direction: "center", offset: [-15, 20] }
          );
          markers.push(mark)
     })
   },
   shareDatas(position){
     bus.$emit("datas", {
       "position" : position 
     })
   },
   addMarker(){
            L.marker([42, 9], {draggable: true, opacity: 0.5})
            .on("dragend", (e) => {
                var marker = e.target;
                var position = marker.getLatLng();
                this.shareDatas(position)
                mapDiv.panTo(new L.LatLng(position.lat, position.lng));
            })  
            .addTo(mapDiv);
   },
    setPosition(p){
      mutations.setPosition(p)
    },
    setZoom(z){
      mutations.setZoom(z);
    } 


 },

  async mounted(){
    const response = await fetch(process.env.VUE_APP_API_HOST + "getAllToponymes");
    let data = await response.json();
    this.locations = data;
    this.setupLeafletMap();
 },
 computed: {
      position() {
        return store.position;
      },
      zoom(){
      return store.zoom;
    }
    },
  
}
</script>

<style scoped>

#mapContainer {
  margin-top: none ;
  margin-right: 3%;
  margin-bottom: 2%;
  width: 45vw;
  height: 70vh;
}

.myLabels{
  background-color: transparent;
  border: none;
  box-shadow: none;
}
button{
  margin: 1%;
}
</style>